import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home'),
    meta: {
      title: '首页',
      inNav: true,
      hideNav: true
    }
  },

  {
    path: '/store',
    name: 'STORE',
    component: () => import('../views/store'),
    meta: {
      title: '创业开店',
      inNav: true,
      hideNav: true
    }
  },
  {
    path: '/store/detail',
    name: 'STOREDETAIL',
    component: () => import('../views/store/detail.vue'),
    meta: {
      title: '无线大空间方案',
      inNav: false,
      hideNav: true,
      navOnlyLogo: true,
      opacitas: true
    }
  },
  {
    path: '/zaomeng',
    name: 'ZMVR',
    component: () => import('../views/zaomeng'),
    meta: {
      title: '造梦VR',
      inNav: true,
      hideNav: true
    }
  },
  {
    path: '/mengjing',
    name: 'MJVR',
    component: () => import('../views/mengjing/index2.vue'),
    meta: {
      title: '梦境VR',
      inNav: true,
      hideNav: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about'),
    meta: {
      title: '关于造梦',
      inNav: true,
      hideNav: true
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/download'),
    meta: {
      title: '产品下载',
      inNav: true,
      opacitas: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact'),
    meta: {
      title: '联系我们',
      inNav: false,
      hideNav: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // next()
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    window.location.replace('/m/#' + to.path)
    // if (!/^\/m\//.test(window.location.pathname)) window.location.href = '/m' + to.path
    // window.location.href = '/m/#/' + to.path
  } else next()
})

export default router
