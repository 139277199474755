import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css'
import './global.scss'
import '@/assets/fonts/iconfont/iconfont.css'
import '@/assets/fonts/iconfont_zamer/iconfont.css'
import VueMarquee from 'vue-marquee-component'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
 
Vue.use(VueMarquee)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
Vue.use(VueAwesomeSwiper, {})

import { randomKey, hwcdnLink } from '@/utils'
Vue.prototype.$randomKey = randomKey
Vue.prototype.$hwcdnLink = hwcdnLink

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
