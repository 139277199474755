const randomKey = () => {
  return parseInt(Math.random() * 1000000)
}

const hwcdnLink = (path) => {
  return `http://hwcdn.zmvr.com/${path}`
}

export {
  randomKey,
  hwcdnLink
}