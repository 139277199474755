<template>
  <div id="app">
    <page-nav ref="nav" />
    <router-view @transNavBar="handleTransNavBar" />

    <div class="float-form" :class="{show: showForm}">
      <div class="close" @click="showForm=false">
        <i class="iconfont icon-close"></i>
      </div>
      <iframe class="form-iframe" src="/form/index.html" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import pageNav from "@/components/Nav";

export default {
  components: {
    pageNav,
  },
  data() {
    return {
      handleTransNavBar: () => {},

      showForm: false,
    };
  },
  watch: {
    $route(r) {
      window.scrollTo(0, 0);

      console.log(r);
      const pathArr = ["/store", "/zaomeng", "/store/detail"];
      if (pathArr.includes(r.path)) {
        this.showForm = true;
      } else this.showForm = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleTransNavBar = this.$refs.nav.handleTrans;
    });

    window.addEventListener("message", this.handleMessage);
  },
  methods: {
    handleMessage(e) {
      e.data === "hide_form" && (this.showForm = false);
    },
  },
};
</script>

<style lang="scss">
html,
body {
  overflow-x: hidden;
  height: auto !important;
}

.float-form {
  width: 240px;
  height: 260px;
  background-color: #eee;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
  bottom: 100px;
  z-index: 99999;
  transition: transform 0.3s;
  border-radius: 4px;
  overflow: hidden;
  &.show {
    transform: translate(0, -50%);
  }

  .close {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    padding: 3px;
    .icon-close {
      color: rgba(#ccc, 1);
      user-select: none;
    }
  }

  .form-iframe {
    width: 100%;
    height: 100%;
  }
}
</style>