<template>
  <header :class="{translucence, hide, opacitas, showMenu:menu.show, openMenu:menu.open}">
    <div class="container">
      <nav v-if="routes.length">
        <router-link class="link-logo" :to="routes[0].path">
          <div class="logo">
            <img class="logo-img" :src="require('@/assets/logo@2x.png')" alt="">
          </div>
        </router-link>
        <div class="item-box" v-if="!onlylogo">
          <template v-for="(A,a) in routes.slice(1, routes.length)">
            <div v-if="A.meta.inNav" class="item" :key="a">
              <router-link class="link-page" :to="A.path" :key="$randomKey()">{{ A.meta.title }}</router-link>
            </div>
          </template>
        </div>
      </nav>
      <div class="btn-menu" @click="menu.open=!menu.open">
        <div class="btn"></div>
      </div>
    </div>
  </header>
</template>

<script>
import router from "@/router";

export default {
  name: "zmnav",
  data() {
    return {
      routes: [],

      showNav: true,

      hide: false,
      opacitas: false,

      menu: {
        show: false,
        open: true,
      },

      onlylogo: false
    };
  },
  computed: {
    translucence() {
      return this.$route.name != "Home";
    },
  },
  mounted() {
    console.log(router.options.routes);
    // this.routes = router.options.routes.map(item => item.meta.inNav && item)
    this.routes = router.options.routes;
  },
  watch: {
    $route(route) {
      // 先取消隐藏
      this.hide = false;

      // 是否透明
      this.opacitas = route.meta.opacitas;

      if (!route.meta.hideNav) window.onscroll = null;
      else this.handleChangeShowStatus();
      
      this.onlylogo = route.meta.navOnlyLogo

      if (route.path != "/") {
        this.menu = {
          show: false,
          open: true,
        };

        this.routes[5].meta.inNav = true;
      } else {
        this.routes[5].meta.inNav = false;
      }
    },
  },
  methods: {
    /** 监听滚动条高度改变状态栏显示隐藏状态 */
    handleChangeShowStatus() {
      let oldTop = 0;
      window.onscroll = () => {
        let top = document.scrollingElement.scrollTop;
        // 向上
        if (oldTop > top) {
          this.hide = false;
        }
        // 向下
        else if (oldTop >= 450) {
          this.hide = true;
        }
        oldTop = top;
        
        !this.$route.meta.navOnlyLogo && (this.opacitas = oldTop >= 450)
      };
    },

    handleTrans(option) {
      this.menu = option;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 120px;
  box-sizing: border-box;
  z-index: 999;
  transition: transform 0.3s, background-color 0.3s;

  &.translucence {
    background-color: rgba($color: #323436, $alpha: 0.1);
  }

  &.hide {
    transform: translateY(-100%);
  }

  &.opacitas {
    background-color: rgba($color: #323436, $alpha: 1);
  }

  &.showMenu {
    .btn-menu {
      display: flex;
    }
  }

  &.openMenu {
    .item-box {
      transform: translateX(0);
    }
    .btn-menu .btn {
      background-color: rgba(#fff, 0);
      &::before {
        transform: translateY(0) rotate(45deg);
      }
      &::after {
        background-color: rgba(#fff, 0.9);
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
}

.container {
  max-width: 1680px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  .link-logo {
    margin-right: auto;

    .logo {
      width: 124px;
      height: 28px;
      //background-color: #aaa;
      margin-right: auto;
      display: inline-block;
      position: relative;
      transition: transform 0.3s;

      .logo-img {
        display: block;
        width: 100%;
      }

      &:after {
        content: "造梦科技";
        position: absolute;
        width: 100%;
        text-align-last: justify;
        left: 0;
        bottom: 0;
        transform: translateY(calc(100% + 4px));
        font-size: 14px;
        line-height: 1;
        color: #fff;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        transform: translateY(-9px);

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .item-box {
    height: 100%;
    display: flex;
    transform: translateX(100%);
    transition: transform 0.3s;

    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      position: relative;
      &:not(:first-child) {
        margin-left: 60px;
      }

      &:before {
        content: "";
        width: 100%;
        height: 6px;
        background-color: #0edee3;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        transition: transform 0.3s;
      }

      &:hover {
        &:before {
          transform: translateY(0);
        }
      }

      .link-page {
        text-decoration: none;
        // position: relative;
        color: #fff;
        line-height: 1;
        font-size: 24px;

        /* &:before {
        content: "";
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
      } */

        &.router-link-exact-active {
          &:before {
            content: "";
            width: 100%;
            height: 6px;
            background-color: #0edee3;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(0);
            transition: transform 0.3s;
          }
        }
      }
    }
  }
}

.btn-menu {
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  cursor: pointer;

  .btn {
    $btn-h: 30px;
    $line-w: 30px;
    $line-h: 5px;

    width: $line-w;
    height: $line-h;
    border-radius: $line-h / 2;
    background-color: rgba(#fff, 0.5);
    position: relative;
    transition: background-color 0.3s;

    &::before,
    &::after {
      content: "";
      width: $line-w;
      height: $line-h;
      border-radius: $line-h / 2;
      position: absolute;
      left: 0;
      transition: transform 0.3s, background-color 0.3s;
    }
    &::before {
      top: 0;
      transform: translateY(-(($btn-h - $line-h * 3) / 2) - $line-h);
      background-color: rgba(#fff, 0.9);
    }
    &::after {
      bottom: 0;
      transform: translateY(($btn-h - $line-h * 3) / 2 + $line-h);
      background-color: rgba(#fff, 0.2);
    }
  }
}
</style>
